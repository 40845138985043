<template>
  <header class="site-header">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-5">
          <div class="ratio ratio-1x1">
            <transition name="headshot">
              <div class="content" v-if="isLoaded">
                <img
                  class="headshot"
                  src="../assets/nicky.jpg"
                  alt="Nicky Zeeman"
                />
              </div>
            </transition>
          </div>
        </div>
        <div class="col-md-6 col-lg-7">
          <h1 class="intro">
            Hoi.<br />
            Ik ben <span class="intro__highlight">Nicky Zeeman</span><br />
            front-end developer.
          </h1>
        </div>
      </div>

      <!-- <button type="button" class="btn btn-scroll-down">
        <img
          src="../assets/icon-scroll-down.svg"
          width="40"
          height="19"
          alt="Scroll down icon"
        />
        <span>Scroll down</span>
      </button> -->
    </div>
  </header>
  <!-- <HelloWorld msg="Nicky Zeeman" /> -->
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  data() {
    return {
      isLoaded: false,
    };
  },
  // components: {
  //   HelloWorld,
  // },
  mounted() {
    //const img = document.querySelector(".content");
    // Todo refactor using <transitions>
    //img.classList.add("is-loaded");
    this.isLoaded = true;
  },
};
</script>

<style lang="scss">
.headshot-enter-active,
.headshot-leave-active {
  transition: top 0.5s ease, clip-path 0.2s ease 0.8s;
}
.headshot-enter-from,
.headshot-leave-to {
  clip-path: inset(20% 0% 20% 60%);
  top: 100% !important;
}

.headshot-enter-to,
.headshot-leave-from {
  top: 0% !important;
  clip-path: inset(0 0 0 0);
}

.site-header {
  height: 100vh;
  max-height: 800px;
  .container,
  .row {
    height: 100%;
  }

  .container {
    position: relative;
  }

  .row {
    flex-direction: column;
    justify-content: flex-start;
  }

  .content {
    position: absolute;
  }

  .headshot {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }

  .intro {
    @include font-semibold();
    line-height: 1;
    @include fluid-type($breakpoint-xs, $breakpoint-xl, 65px, 100px);

    &__highlight {
      @include font-regular;

      background-image: linear-gradient($brand-blue, $brand-blue);
      background-size: 100% 35%;
      background-position: 0 90%;
      background-repeat: no-repeat;
    }
  }

  .btn-scroll-down {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(0, -200%) rotate(-90deg);
    line-height: 1;

    span {
      font-size: $font-size-tiny;
      margin-left: 10px;
    }
  }

  @media (min-width: $breakpoint-md) {
    .row {
      flex-direction: row;
      align-items: center;
    }

    .btn-scroll-down {
      right: 0;
    }
  }
  @media (min-width: $breakpoint-lg) {
    .container,
    .row {
      height: 100%;
    }
  }
}
</style>
